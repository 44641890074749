import Link from "next/link";
import Image from "next/image";
import { TagVariant } from "@/lib/types";
import Tag from "@/components/ui/tag";
import { Button } from "@/components/ui/button";
import Arrow from "@/components/icons/Arrow";
import { cva } from "class-variance-authority";
import Video from "./Video";
import { cn, strapiMediaAlt, strapiMediaUrl } from "@/lib/utils";
import { formatDate } from "@/lib/api-helpers";

interface CardProps {
  variant: TagVariant;
  urlVariant?: string;
  square?: boolean;
  item: any;
}

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const Card = ({ item, variant }: CardProps) => {
  let { title, shortDescription, thumbnail, url } = item;
  return (
    <LinkWrapper variant={variant} href={url} className="group h-full">
      <article className="flex h-full flex-col gap-5">
        <figure className="relative aspect-square max-h-[250px]">
          {thumbnail ? (
            <Image
              src={strapiMediaUrl(thumbnail)}
              alt={strapiMediaAlt(thumbnail)}
              className="rounded object-cover transition-transform duration-500 group-hover:scale-105"
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
              fill
            />
          ) : (
            <div className="h-full w-full bg-gray-300" />
          )}
        </figure>

        <h3
          className={cn(
            variant === "Over ons" ? "!normal-case" : "",
            "max-w-[300px] text-balance font-oswald text-xl font-bold uppercase !leading-normal",
            websiteId === "jsc" && "text-black"
          )}
        >
          {title}
        </h3>

        {shortDescription && <p className="support -mt-2.5 line-clamp-8 text-balance">{shortDescription}</p>}

        {websiteId === "nsc" && (
          <Button variant="link" size="link" className="mt-auto text-wrap text-start">
            Lees meer{" "}
            <Arrow
              color={
                variant === "download" || variant === "detail" ? "var(--color-white)" : "var(--color-nsc-dark-blue)"
              }
              className="ml-3 transition-transform group-hover:translate-x-1"
            />
          </Button>
        )}
      </article>
    </LinkWrapper>
  );
};

const LinkWrapper = ({
  children,
  href,
  external,
  className,
  variant,
}: {
  children: React.ReactNode;
  external?: boolean;
  href: string;
  className?: string;
  variant: TagVariant;
}) => {
  return variant === "Video" ? (
    children
  ) : external ? (
    <a href={href} className={className} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ) : (
    <Link className={className} href={href}>
      {children}
    </Link>
  );
};

export default Card;
