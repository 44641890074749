"use client";

import { cva } from "class-variance-authority";

import { TagVariant } from "@/lib/types";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./tooltip";
import { useStore } from "@/store";
import { getTextColorByBgColor } from "@/lib/utils";

interface TagProps {
  variant: TagVariant;
  rounded?: boolean;
  noText?: boolean;
  color?: string;
}

const websiteId = (process.env.NEXT_PUBLIC_WEBSITE_ID as "nsc" | "jsc") || "nsc";

const tagVariants = cva(
  "font-light flex gap-2 items-center font-roboto after:relative after:block after:translate-y-px after:h-[11px] after:w-[11px] after:rounded-full",
  {
    variants: {
      variant: {
        Agenda: "after:bg-nsc-mint",
        Nieuws: "after:bg-nsc-mint",
        "Jong Sociaal Contract": "after:bg-nsc-ice-blue",
        Europa: "after:bg-nsc-europe-blue",
        "Goed bestuur": "after:bg-nsc-yellow",
        Bestaanszekerheid: "after:bg-nsc-yellow",
        Wonen: "after:bg-nsc-yellow",
        Migratie: "after:bg-nsc-yellow",
        "Internationale stabiliteit": "after:bg-nsc-yellow",
        "Gezonde leefomgeving": "after:bg-nsc-yellow",
        "Digitale leefomgeving": "after:bg-nsc-yellow",
        Gezondheidszorg: "after:bg-nsc-yellow",
        Mensen: "after:bg-nsc-yellow",
        "Over ons": "after:bg-nsc-orange",
        Speerpunt: "after:bg-nsc-yellow",
        jsc: "after:bg-jsc-yellow",
        Pensioenen: "after:bg-nsc-orange",
        Gemeenteraadsverkiezingen: "after:bg-nsc-dark-brown",
      },
    },
    defaultVariants: {
      variant: "Agenda",
    },
  }
);

const roundedTagVariants = cva(
  "rounded-full flex items-center justify-center text-[14px] font-roboto font-medium w-6 h-6 md:w-10 md:h-10",
  {
    variants: {
      variant: {
        Agenda: "bg-nsc-mint text-nsc-dark-blue",
        Nieuws: "bg-nsc-mint text-nsc-dark-blue",
        "Jong Sociaal Contract": "bg-nsc-ice-blue text-nsc-dark-blue",
        Europa: "bg-nsc-europe-blue text-white",
        "Goed bestuur": "bg-nsc-yellow text-nsc-dark-blue",
        Bestaanszekerheid: "bg-nsc-yellow text-nsc-dark-blue",
        Wonen: "bg-nsc-brown text-nsc-dark-blue",
        Migratie: "bg-nsc-yellow text-nsc-dark-blue",
        "Internationale stabiliteit": "bg-nsc-yellow text-nsc-dark-blue",
        "Gezonde leefomgeving": "bg-nsc-yellow text-nsc-dark-blue",
        "Digitale leefomgeving": "bg-nsc-yellow text-nsc-dark-blue",
        Gezondheidszorg: "bg-nsc-yellow text-nsc-dark-blue",
        Mensen: "bg-nsc-yellow text-nsc-dark-blue",
        "Over ons": "bg-nsc-orange text-nsc-dark-blue",
        Speerpunt: "bg-nsc-yellow text-nsc-dark-blue",
        "Een goed pensioen": "bg-nsc-yellow text-nsc-dark-blue",
        jsc: "bg-jsc-yellow text-black",
        Pensioenen: "bg-nsc-orange text-nsc-dark-blue",
        Gemeenteraadsverkiezingen: "bg-nsc-dark-brown text-white",
      },
    },
    defaultVariants: {
      variant: "Agenda",
    },
  }
);

const getTagInitials = (variant: TagVariant) => {
  switch (variant) {
    case "Agenda":
      return "A";
    case "Speerpunt":
      return "SP";
    case "Over ons":
      return "O";
    case "Nieuws":
      return "N";
    case "Jong Sociaal Contract":
      return "JSC";
    case "Europa":
      return "EU";
    case "Goed bestuur":
      return "GB";
    case "Bestaanszekerheid":
      return "BZ";
    case "Wonen":
      return "W";
    case "Migratie":
      return "M";
    case "Internationale stabiliteit":
      return "IS";
    case "Gezonde leefomgeving":
      return "GL";
    case "Digitale leefomgeving":
      return "DL";
    case "Gezondheidszorg":
      return "GZ";
    case "Pensioenen":
      return "P";
    case "Gemeenteraadsverkiezingen":
      return "GV";
    case "Mensen":
      return "M";
    case "Een goed pensioen":
      return "P";
    default:
      return "";
  }
};

const Tag = ({ variant, rounded, noText, color }: TagProps) => {
  const { tagsData } = useStore();
  return rounded ? (
    <TooltipProvider delayDuration={300}>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={roundedTagVariants({ variant: websiteId === "jsc" ? "jsc" : (variant as any) })}>
            {getTagInitials(variant as TagVariant)}
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{(tagsData.find((tag: any) => tag.tag_id === variant) as any)?.tag_info}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  ) : (
    <p
      className={tagVariants({ variant: websiteId === "jsc" ? "jsc" : (variant as any) })}
      style={
        color
          ? {
              color,
            }
          : {}
      }
    >
      {noText ? "" : variant}
    </p>
  );
};

export default Tag;
