"use client";

import { TagVariant } from "@/lib/types";
import { cn, getUniqueTags } from "@/lib/utils";
import { useEffect, useState } from "react";
import { getSliderTitle } from "./Slider";
import Tag from "@/components/ui/tag";
import Arrow from "@/components/icons/Arrow";
import RichText from "@/components/general/nsc/RichText";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { useSearchParams } from "next/navigation";
import { Minus, Plus } from "lucide-react";

const AccordionArchiveSection = ({ items, variant, noTags, data }: any) => {
  let uniqueTags: TagVariant[] = [];

  if (!noTags) {
    items = items.map((item: any) => {
      return {
        ...item,
        attributes: {
          ...item.attributes,
          tags: item.attributes.tags.map((tag: any) => {
            return tag.name;
          }),
        },
      };
    });

    uniqueTags = getUniqueTags(items);
    if (variant !== "Over ons") {
      uniqueTags = getUniqueTags(items);
    } else {
      uniqueTags = ["Over ons"];
    }
  }

  const [openItem, setOpenItem] = useState(items[0]);

  const searchParams = useSearchParams();
  const item = searchParams.get("item");

  const scrollToItem = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (item && items.find((i: any) => item === i.title.toLowerCase().replaceAll(" ", "-"))) {
      scrollToItem(item);
      setOpenItem(items.find((i: any) => item === i.title.toLowerCase().replaceAll(" ", "-")));
    }
  }, [item]); // TODO: Fix this warning

  return (
    <section className="col-span-12 mb-16 md:col-span-10 md:col-start-2 md:mb-24 lg:col-span-8 lg:col-start-3">
      {/* Mobile */}
      <div>
        {variant !== "Speerpunt" && (
          <div className="col-span-12 mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
            <h2 className="header-3">{data?.title || getSliderTitle(variant)}</h2>
            <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
            <div className="flex gap-2">
              {uniqueTags.map((tag) => (
                <Tag key={tag} variant={tag} rounded />
              ))}
            </div>
          </div>
        )}

        <Accordion type="multiple" className="w-full" defaultValue={[openItem?.id]}>
          {items.map((item: any) => (
            <AccordionItem key={item.id} value={item.id}>
              <AccordionTrigger
                id={`accordion-${item.id}`}
                onClick={(e) => {
                  setOpenItem(item);
                }}
              >
                {item?.attributes?.title || item.title}
              </AccordionTrigger>
              <AccordionContent>
                <RichText
                  data={item?.attributes?.shortDescription || item?.attributes?.description || item?.description}
                  className="rich-text"
                />
              </AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </section>
  );
};

export default AccordionArchiveSection;
