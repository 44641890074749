"use client";

import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { cn, getUniqueTags } from "@/lib/utils";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Card from "@/components/general/Card";
import CustomCard from "@/components/general/CustomCard";
import { cva } from "class-variance-authority";
import { ButtonLink } from "@/components/ui/button";
import { TagVariant } from "@/lib/types";

interface SliderProps {
  variant: TagVariant;
  items: any;
  data: any;
  noHeader?: boolean;
}

export const getSliderTitle = (variant: TagVariant) => {
  switch (variant) {
    case "Speerpunt":
      return "Onze speerpunten";
    case "Over ons":
      return "Over ons";
    case "Nieuws":
      return "Nieuws";
    case "Agenda":
      return "Agenda";
    case "Video":
      return "Videos";
    default:
      return "Nieuws";
  }
};

const sliderVariants = cva("pl-5 grow-0 shrink-0 min-w-0 ", {
  variants: {
    sizes: {
      two_cards_slider: "basis-[80%] md:basis-[33.333%] lg:basis-[50%]",
      three_cards_slider: "basis-[80%] md:basis-[50%] lg:basis-[33.333%]",
      four_cards_slider: "basis-[80%] md:basis-[33.333%] lg:basis-[25%]",
      three_cards_grid: "basis-[80%] md:basis-[50%] lg:basis-[33.333%]",
      four_cards_grid: "basis-[80%] md:basis-[33.333%] lg:basis-[25%]",
      two_cards_grid: "basis-[80%] md:basis-[33.333%] lg:basis-[50%]",
    },
  },
  defaultVariants: {
    sizes: "three_cards_slider",
  },
});

const Slider = ({ variant, data, items, noHeader }: SliderProps) => {
  const [api, setApi] = useState<CarouselApi>();

  const [current, setCurrent] = useState(0);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!api) {
      return;
    }

    setCount(api.scrollSnapList().length);
    setCurrent(api.selectedScrollSnap() + 1);

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap() + 1);
    });
  }, [api]);

  if (!items) return null;

  items = items.map((item: any) => {
    return {
      ...item,
      attributes: {
        ...item.attributes,
        tags: item.attributes?.tags ? item.attributes.tags.map((tag: any) => tag.name) : [],
      },
    };
  });

  let uniqueTags: TagVariant[] = getUniqueTags(items);
  if (variant !== "Over ons") {
    uniqueTags = getUniqueTags(items);
  } else {
    uniqueTags = ["Over ons"];
  }

  return (
    <section className="w-full">
      {!noHeader && (
        <div className="mb-6 flex flex-wrap items-center gap-4 md:mb-12 md:gap-6">
          <h2 className="header-3">{data.title || getSliderTitle(variant)}</h2>
          <div className="h-px w-full flex-1 bg-nsc-dark-blue opacity-40" />
          {/* <div className=" hidden">
            {uniqueTags.map((tag) => (
              <Tag key={tag} variant={tag} rounded />
            ))}
          </div> */}
        </div>
      )}
      {data?.subtitle && (
        <h2 className="header-2 mb-2 mt-12 !text-[28px] md:mb-12 md:text-center md:!text-[48px]">{data.subtitle}</h2>
      )}

      <div className={cn("-ml-5 hidden w-auto", data.type?.includes("grid") && "flex-wrap gap-y-16 lg:flex")}>
        {items.map((item: any) => (
          <div className={sliderVariants({ sizes: data.type })} key={item.id}>
            {variant === "Custom" ? (
              <CustomCard variant={variant} item={item} />
            ) : (
              <Card variant={variant} item={item} />
            )}
          </div>
        ))}
      </div>

      <Carousel
        opts={{
          align: "start",
        }}
        className={cn("w-screen md:w-auto", data.type?.includes("grid") && "lg:hidden")}
        setApi={setApi}
      >
        <CarouselContent className="mb-8 md:mb-12">
          {items.map((item: any) => (
            <CarouselItem key={item.id} className={sliderVariants({ sizes: data.type })}>
              {variant === "Custom" ? (
                <CustomCard variant={variant} item={item} />
              ) : (
                <Card variant={variant} item={item} />
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
        <div className="flex w-[calc(100vw-20px)] items-center justify-between md:w-full">
          <CarouselPrevious />
          <div className="flex justify-center gap-2">
            {count > 1 &&
              count < 6 &&
              Array.from({ length: count }).map((_, index) => (
                <motion.button
                  aria-label={`Scroll naar slide ${index + 1}`}
                  onClick={() => api?.scrollTo(index)}
                  animate={{ opacity: current === index + 1 ? 1 : 0.5 }}
                  key={index}
                  className="h-4 w-4 rounded-full bg-nsc-dark-blue p-0"
                />
              ))}
          </div>
          <CarouselNext />
        </div>
      </Carousel>
      {variant === "Nieuws" && (
        <ButtonLink className="mx-auto mt-12" href="/nieuws">
          Ga naar het nieuwsoverzicht
        </ButtonLink>
      )}
    </section>
  );
};

export default Slider;
